import React from 'react';
import { useEffect, useState } from 'react'
import PaymentOption from './payment-option'
import './payment-options.css'
const paymentOptions = [
    {
        name: "Tarjeta de crédito o débito",
        icons:[
            "/assets/icons/payments/visa-loose.svg",
            "/assets/icons/payments/mastercard-loose.svg",
            "/assets/icons/payments/amex-loose.svg",
            "/assets/icons/payments/discover-loose.svg",
            "/assets/icons/payments/jcb.svg"
        ],
        type: "card",
        extra: "2.4%"
    },
    {
        name: "Tarjeta de crédito o débito",
        icons:[
            "/assets/icons/payments/visa-loose.svg",
            "/assets/icons/payments/mastercard-loose.svg",
            "/assets/icons/payments/amex-loose.svg",
            "/assets/icons/payments/discover-loose.svg",
            "/assets/icons/payments/jcb.svg"
        ],
        type: "cardizy",
        extra: "2.4%"
    },
    {
        name: "Google Pay",
        icons:[
            "/assets/icons/payments/google-pay.svg"
        ],
        type: "googlepay",
        extra: "2.4%"
    },
    {
        name: "PayPal",
        icons:[
            "/assets/icons/payments/paypal.svg"
        ],
        type: "paypal",
        extra: "2.4%"
    },
    {
        name: "Criptomonedas",
        icons:[
            "/assets/icons/payments/bitcoin.svg",
            "/assets/icons/payments/ethereum.svg",
            "/assets/icons/payments/ripple.svg"
        ],
        type: "cripto",
        extra: "2.4%"
    }
]

const PaymentOptions = ({payClientExtra = true}) => {
    const [paymentActive, setPaymentActive] = useState("")
    const [status, setStatus] = useState(false);
    const [options, setOptions] = useState([])
    const [activeOption, setActiveOption] = useState(["card","cardizy"])
   
    useEffect(()=>{
        setOptions(paymentOptions.filter((option)=>activeOption.includes(option.type)));
    },[])
    
    return (
        <div className='tripayer-component-options'>
            {options.map(({icons, name, type, extra}, i) =>(<PaymentOption key={type} active={paymentActive===type} type={type} setStatus={setStatus} setPaymentActive={setPaymentActive} icons={icons} name={name} extra={extra} extraPay={payClientExtra} />))}
        </div>
    )
}

export default PaymentOptions