import React from 'react';
import { Elements, LinkAuthenticationElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import "./stripe-type.css"

const StripeType  = ({tokens,setTokens}) =>{
    const [ clientSecret, setClientSecret ] = useState('');
    const [ stripePromise, setStripePromise ] = useState(null);
     console.log(tokens)
    useEffect(() => {
      setStripePromise(loadStripe("pk_test_51PBK9sAWq7NlFQghCYgF52Wjl2HAqktBGNEWQZJJWtiIO9PLZXX87n4OCzq7cvLKSi3URH2QFe7ubLD7NeawISym00XHkybLWs"));
 
      const tokenExist = tokens.find(token => token.client === "stripe")
      if(!tokenExist){
        fetch("http://localhost:8000/stripe/payment-intent", {
          method: "POST",
          body: JSON.stringify({ order: "COD123", amount: 100 }),
          redirect: "follow"
        })
          .then((response) => response.json())
          .then(({ clientSecret }) => {
            setClientSecret(clientSecret);
            setTokens((prev) => ([...prev, { client: "stripe", clientSecret }]));
          })
          .catch(console.error);
      }else{
        setClientSecret(tokenExist?.clientSecret)
      }
    }, []);

    if(!clientSecret) return<div className="spiner_parent">
       <span className="spiner_container"/> 
       <span>Loading...</span>
    </div>
    return(
        <Elements stripe={stripePromise} options={{ clientSecret}}>
          <CheckoutForm />
        </Elements>
    )
}

const CheckoutForm = () =>{
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
  
      setIsLoading(true);
  
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.origin}/completion`,

        },
        redirect: "if_required",
      });
      if(!!paymentIntent){
        console.log("Paso");
        setMessage("");
        setIsLoading(false);
        return;
      }
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error?.type === "card_error" || error?.type === "validation_error" || error?.type ==="invalid_request_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occured.");
      }
  
      setIsLoading(false);
    }
  
    return (
      <form   onSubmit={handleSubmit}>
        <PaymentElement id="payment-element"  />
        <button disabled={isLoading || !stripe || !elements} id="submit" className="pay-now-button">
          <div id="button-text">
            {isLoading ? <div className="spinner" id="spinner">Cargando...</div> :  "Continuar" }
          </div>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    )
  }

export default StripeType