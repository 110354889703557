import React from 'react';
import { useMemo, useState } from "react";
import StripeType from "./stripe-type";
import './payment-options.css'

const PaymentOption = ({ active = false, setStatus, name, icons, setPaymentActive, type, extra, extraPay }) => {

    const [tokens, setTokens] = useState([])

    const handleClickOption = () => {
        setStatus(!active);
        if (!active) {
            setPaymentActive(type)
        } else {
            setPaymentActive("")
        }

        let option = document.getElementById(`tripayer-${type}`);
        option.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    
    const renderStripe = useMemo(()=>(type=="card"? <StripeType tokens={tokens} setTokens={setTokens}/>: <></>),[type,tokens])

    return (
        <div className='tripayer-component-option' id={`tripayer-${type}`} >
            <div className='tripayer-component-header' onClick={handleClickOption}>
                <div className='tripayer-component-option-child'>
                    <p className='tripayer-component-option-text'>{name}</p> <div className="tripayer-component-option-icons">{icons?.map((icon) => (<img className="tripayer-component-option-icon-image" src={icon} height="24px" />))}</div>
                </div>
                <div className="tripayer-component-accordion">
                    {extraPay &&  <span className="tripayer-component-accordion-extra" style={{marginRight:"10px"}}>adicional {extra}</span>}
                    <svg className={`tripayer-AccordionButton_chevron ${active && "active"}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="currentColor" width="16px" height="16px"><path d="M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z"></path></svg>
                </div>
            </div>
            {active && <div className='tripayer-component-content'>
                  {type === "card" && renderStripe}
            </div>}
        </div>)
}

export default PaymentOption